import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"
import iconEnvelop from "images/icons/envelop-white.svg"
import iconEnvelopHover from "images/icons/envelop-red.svg"
import {window} from "browser-monads";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {Link, navigate} from 'gatsby'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    background-color: ${props => props.theme.colors.blue};
    text-align: center;
    padding: 1rem;
    
    h3 {
        text-transform: uppercase;
        font-size: 1.375rem;
        
        @media ${media.sm} {
            font-size: 2rem;
        }
        @media ${media.lg} {
            font-size: 2.25rem;
        }
    }
    
    .text--yellow {
        color: ${props => props.theme.colors.yellow};
    }
    
    .upcoming-week {
        position: relative;
        //width: 60%;
        line-height: 1;
        text-align: center;
        letter-spacing: -0.5px;
        margin: 0 auto;
        font-size: 1.75rem;
        
         @media ${media.sm} {
                font-size: 2.25rem;
         }
    }

  .btn-wrapper {
    @media ${media.md} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 600px;
      margin: 0 auto 0;
    }

    a {
      margin: 0.5rem auto 0;
      @media ${media.md} {
        margin: 0 auto;
      }
    }
  }
`

const TicketBtn = styled(Link)`
  width: 177px;
  height: 24px;
  background-color: ${props => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  margin: 0 auto;

  @media ${media.sm} {
    width: 272px;
    height: 36px;
  }

  span {
    color: ${props => props.theme.colors.yellow};
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: "Clarendon W01 Bold", Arial, sans-serif;
    font-weight: bold;

    @media ${media.sm} {
      font-size: 1.75rem;
    }
  }

  .icon-envelop {
    background: url(${iconEnvelop}) no-repeat center center;
    display: block;
    margin-right: 10px;
    width: 24px;
    height: 24px;

    @media ${media.sm} {
      width: 30px;
      height: 30px;
    }
  }


  // &:hover {
  //     .icon-envelop {
    //         background: url(${iconEnvelopHover}) no-repeat center center;
  //     }
  // }
`
const TicketBtnTwo = styled.a`
  width: 177px;
  height: 24px;
  background-color: ${props => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  margin: 0 auto;

  @media ${media.sm} {
    width: 272px;
    height: 36px;
  }

  span {
    color: ${props => props.theme.colors.yellow};
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: "Clarendon W01 Bold", Arial, sans-serif;
    font-weight: bold;

    @media ${media.sm} {
      font-size: 1.75rem;
    }
  }

  .icon-envelop {
    background: url(${iconEnvelop}) no-repeat center center;
    display: block;
    margin-right: 10px;
    width: 24px;
    height: 24px;

    @media ${media.sm} {
      width: 30px;
      height: 30px;
    }
  }


  // &:hover {
  //     .icon-envelop {
    //         background: url(${iconEnvelopHover}) no-repeat center center;
  //     }
  // }
`

class PerformanceBar extends Component {
    openSignupModal = () => {
        if (typeof window.tx !== 'undefined') {
            window.tx({
                widgetId: 'TKaMBlondon',
                configId: '3b435da5',
                type: 'onboarding',
                onDismiss: (obj) => {
                    if (obj) {
                        console.log(obj)
                        const listFields = {
                          'NAME': obj.user.name,
                          'REASON': obj.selected.questions["What is your main reason for seeing ‘To Kill a Mockingbird’?"][0],
                          'GOWITH': obj.selected.questions["Who are you<br />planning to go with?"][0],
                          'MARKETING': typeof obj.selected.terms["I would like to opt-in to hear about productions from the producers of ‘To Kill a Mockingbird’"] !== 'undefined' ? "Yes" : "No"
                      }
                      // const listFields = {
                      //     'NAME': obj.user.name,
                      //     'REASON': obj.selected.questions.shows[0],
                      //     'GOWITH': obj.selected.questions.who_with[0],
                      //     'MARKETING': typeof obj.selected.terms.show !== 'undefined' ? "Yes" : "No"
                      // }


                        addToMailchimp(obj.user.email, listFields) // listFields are optional if you are only capturing the email address.
                            .then(data => {
                                console.log(data)
                            })
                        navigate(
                            "/thank-you/",
                            {
                                state: {
                                    thankYou: true,
                                    name: obj.user.name
                                },
                            }
                        )
                    }
                },
                onFormComplete: (user) => {
                    if (typeof(window) !== "undefined") {
                        window.gtag = window.gtag || [];
                        window.gtag('event', 'conversion', {'send_to': 'AW-699463522/Ei_hCILdvrEBEOLuw80C'});
                    }
                }
            })
        }
    }

    render() {
        return (
            <Wrapper>
                <div className="ticketing">
                    <div className="upcoming-week">
                        {/*<div className="text--yellow">PERFORMANCES FROM 10 MARCH 2022</div>*/}
                        <div className="btn-wrapper">
                            {/*<TicketBtn to="/tickets/">*/}
                            {/*    <span>Book tickets</span>*/}
                            {/*</TicketBtn>*/}
                            <TicketBtnTwo  >
                                <span onClick={this.openSignupModal} className="icon-envelop"/>
                                <span onClick={this.openSignupModal}>Sign Up Now</span>
                            </TicketBtnTwo>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default PerformanceBar;
