import React, { Component } from "react";
import Slider from "react-slick";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { media } from "utils/Media";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import stars from "images/icons/tkamb-stars.svg";

const Wrapper = styled.div`
  /* margin: 0 auto 2rem;
    padding: 1rem 0; */
  padding: 3rem 0 3.5rem 0;
  /* min-height: 200px !important; */
  width: 90%;
  margin: 0 auto;
  @media ${media.md} {
    /* min-height: 280px !important; */
  }
  .carousel__item {
    width: 90%;
    margin: 0 auto;
    display: flex;
    .carousel__item-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media ${media.lg} {
        /* min-height: 210px; */
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .stars {
        width: 190px;
        margin: 0 auto;
        @media ${media.lg} {
          width: 190px;
        }
      }

      .title {
        display: block;
        color: ${(props) => props.theme.colors.black};
        text-transform: uppercase;
        line-height: 100%;
        margin: 0.5rem 0;
        font-weight: 700;
        font-size: 1.25rem;
        @media ${media.md} {
          margin: 0.5rem 0 1rem 0;
          font-size: 1.5rem;
          max-width: 1050px;
        }
        q {
          font-weight: 100 !important;
        }
        @media ${media.lg} {
          font-size: 2.25rem;
          margin: 0.5rem 0 0.25rem 0;
        }
      }

      div {
        p {
          text-transform: uppercase;
          margin-bottom: 0;
          font-family: clarendon_urw_narrowlight, Arial, sans-serif;
          font-size: 0.78rem;
          @media ${media.md} {
            font-size: 1.5rem;
          }
        }
      }
      .reviewer {
        color: ${(props) => props.theme.colors.red};
        text-transform: uppercase;
        font-weight: 500;
        margin-top: 0.5rem;
        margin-bottom: 0;
        @media ${media.lg} {
          font-size: 1.4rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

class HomeCarousel extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };

    const items = this.props.data.allHomeCarouselItemsJson.edges;

    const getTextItems = items.map(({ node }, id) => {
      return (
        <div className="carousel__item" key={id}>
          <div className="carousel__item-content">
            {node.stars && (
              <img src={stars} className="stars" alt="" title="" />
            )}
            <p className="title">{`${node.title}`}</p>
            <div dangerouslySetInnerHTML={{ __html: node.subtitle }} />
            <p className="reviewer">{node.reviewer}</p>
          </div>
        </div>
      );
    });

    return (
      <Wrapper>
        <Slider {...settings}>{getTextItems}</Slider>
      </Wrapper>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allHomeCarouselItemsJson {
          edges {
            node {
              stars
              image
              title
              subtitle
              reviewer
            }
          }
        }
      }
    `}
    render={(data) => <HomeCarousel data={data} />}
  />
);
