import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"

const MainTitle = styled.div`
  text-align: left;

  .wrapper {
    max-width: 1920px;
    margin: 2rem auto;
  }

  @media ${media.lg} {
    margin-left: 12%;
  }

  @media ${media.xxl} {
    margin-left: 22vw;
  }

  @media ${media.xxxl} {
    margin-left: 25vw;
  }

  p {
    font-family: "clarendon_urw_narrowlight", Arial, sans-serif;
  }

  .line-4 {
    padding-left: 15.2%;
    @media ${media.lg} {
      padding-left: 0;
    }
  }

  .leftIndent {
    padding-left: 15%;
    position: relative;

    @media ${media.lg} {
      padding-left: 17.5%;
    }
    @media ${media.xl} {
      padding-left: 14%;
    }


    &.last {
      //height: 2vw;
    }

    p {
      text-transform: uppercase;
      color: #fff8d1;
      margin: 0;
      font-size: 2.5rem;
      /* font-size: 9.6vw; */
      /* line-height: 8.6vw; */
      @media ${media.sm} {
        /* font-size: 5.2vw;
        line-height: 4.8vw; */
        font-size: 3rem;
        line-height: 2.5rem;
      }
      @media ${media.md} {
        /* font-size: 5.2vw;
        line-height: 4.8vw; */
        line-height: 2.85rem;
      }
        /* @media ${media.lg} {
                font-size: 5.2vw;
                line-height: 4.4vw;
            } */
      @media ${media.xl} {
        font-size: 69px;
        line-height: 4rem;
      }

      &.blue {
        color: #8693a0;
      }
    }

    .absolute {
      color: #b39d6b;
      font-size: 1rem;
      /* font-size: 3.8vw;
      line-height: 3.8vw; */
      @media ${media.extraSmallMob} {
        font-size: 1.1rem;
      }
      @media ${media.extraLargeMob} {
        font-size: 1.2rem;
      }
      @media ${media.lg} {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 22px;
        line-height: 150%;
      }
      @media ${media.xl} {
        font-size: 24.5px;
      }
      @media ${media.xsl} {
        font-size: 25px;
      }
      @media ${media.xxl} {
        font-size: 26.5px;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    margin: 0;
    color: #fff8d1;
    font-size: 2.5rem;
    /* font-size: 9.6vw;
    line-height: 8.7vw; */
    font-family: "clarendon_urw_narrowlight", Arial, sans-serif;

    @media ${media.sm} {
      /* font-size: 5.2vw;
      line-height: 4.8vw; */
      font-size: 3rem;
      line-height: 3rem;
    }
    @media ${media.lg} {
      /* font-size: 5.2vw;
      line-height: 4.8vw; */
      font-size: 3.5rem;
      line-height: 3rem;
    }
    @media ${media.xl} {
      /* font-size: 5.2vw; */
      font-size: 85px;
      line-height: 4.3rem;
    }

    &.red {
      color: #ff3430;
    }

    &.blue {
      color: #8693a0;
    }
  }

  .smaller {
    padding-left: 5px;
    color: #b39d6b;
    text-transform: none;
    font-size: 1rem;
    @media ${media.extraSmallMob} {
      font-size: 1.1rem;
    }
    @media ${media.extraLargeMob} {
      font-size: 1.2rem;
    }
    @media ${media.lg} {
      font-size: 22px;
      line-height: 150%;
    }
    @media ${media.xl} {
      font-size: 24.5px;
    }
    @media ${media.xsl} {
      font-size: 25px;
    }
    @media ${media.xxl} {
      font-size: 26.5px;
    }
  }

  h2 {
    color: #b39d6b;
    text-transform: uppercase;
    margin: 0;
    font-size: 1rem;
    /* font-size: 3.8vw;
    line-height: 3.8vw; */
    @media ${media.extraSmallMob} {
      font-size: 1.1rem;
    }
    @media ${media.extraLargeMob} {
      font-size: 1.2rem;
    }

    @media ${media.md} {
      font-size: 23px !important;
      line-height: 2rem;
    }
    @media ${media.lg} {
      font-size: 23px !important;
      line-height: 2rem;
    }

    .smaller {
      color: #fff8d1;
      text-transform: uppercase;
      font-size: 3.8vw;
      line-height: 4.2vw;
      padding-left: 0;

      @media ${media.lg} {
        font-size: 1.3vw;
        line-height: 1.5vw;
      }
    }
  }

`

class HomeTitle extends Component {
    render() {
        return (
            <MainTitle>
                <div className="wrapper">
                    {/* <div className="leftIndent mt-4">
                        <h1>Richard Coyle
                            <span className="smaller">is</span>
                        </h1>
                    </div>
                    <div className="leftIndent">
                        <h1 className="blue">Atticus Finch
                            <span className="smaller">in</span>
                        </h1>
                    </div> */}
                    <div className="leftIndent">
                        <h1>Harper Lee’s</h1>
                    </div>
                    <div className="line-4">
                        <h1 className="red">To Kill <br className="d-inline-block d-lg-none"/>a Mockingbird</h1>
                    </div>
                    <div className="leftIndent">
                        <span className="absolute">A new play by</span>
                        <h1>Aaron Sorkin</h1>
                    </div>
                    <div className="leftIndent">
                        <span className="absolute">Directed by</span>
                        <h1>Bartlett Sher</h1>
                    </div>
                    <div className="leftIndent last">
                        <h2>Gielgud Theatre, London<span className="smaller d-none d-lg-inline"> | </span><br
                            className="d-inline-block d-lg-none"/><span
                            className="smaller">A Delfont Mackintosh Theatre</span></h2>
                    </div>
                </div>
            </MainTitle>
        );
    }
}

export default HomeTitle;